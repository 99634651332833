import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {

    static targets = ['burger','mobilenavigation', 'svgburger', 'svgclose' ,'navigation'];

    static values = {
        active: Boolean,
    }

    connect () {
        this.initialized = true
    }

    toggle() {

        this.activeValue = !this.activeValue

        if(this.activeValue) {
            this.svgburgerTarget.classList.add("hidden");
            this.svgcloseTarget.classList.remove("hidden");

            document.body.style.top = `-${window.scrollY}px`
            document.body.style.position = 'fixed'
            document.body.style.overflowY = 'scroll'
            document.body.style.width = '100%'

            this.navigationTarget.classList.remove("hidden");
            setTimeout(() => {
                this.navigationTarget.classList.remove('translate-x-full');
            },50);


        } else {
            if (this.initialized) {

                const scrollY = document.body.style.top;
                document.body.style.position = '';
                document.body.style.top = '';
                document.body.style.overflowY = ''
                document.body.style.width = ''

                window.scrollTo(0, parseInt(scrollY || '0') * -1);

                this.svgburgerTarget.classList.remove("hidden");
                this.svgcloseTarget.classList.add("hidden");

                this.navigationTarget.classList.add('translate-x-full');
                this.navigationTarget.addEventListener('transitionend', () => {
                    this.navigationTarget.classList.add("hidden");
                }, {once: true});
            }
        }

    }





}